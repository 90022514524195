<template>
    <div class="BigBox">
        <div class="TopNav">
            <!-- 按钮 -->
            <img
                @click="$router.push('/')"
                src="../../assets/ConstructionLandscape/Home.png"
                alt=""
            />
        </div>
        <!-- 登录/注册 -->
        <div class="Login">
            <div class="LoginConBox">
                <div class="LoginConTop">
                    <div>湘江段灵境体验</div>
                </div>
                <div class="LoginConInputBox">
                    <div class="UserNameico"></div>
                    <input
                        v-model="LoginForm.mobile"
                        @blur="JyUserNull"
                        type="text"
                        placeholder="请输入账号"
                        id="mobileinput"
                        :style="{'--yzcolor':yzcolor}"
                    />
                </div>
                <div class="LoginConInputBox">
                    <div class="PassWordico"></div>
                    <input
                        v-model="LoginForm.password"
                        @blur="JyPassWord"
                        type="password"
                        placeholder="请输入密码"
                        id="pasinput"
                        :style="{'--yzcolor':yzcolor}"
                    />
                </div>
                <div class="LoginConInputBox">
                    <div class="PassWordico"></div>
                    <input
                        v-model="LoginForm.checkpass"
                        @blur="Jycheckpass"
                        type="password"
                        placeholder="请再次输入密码"
                        :style="{'--yzcolor':yzcolor}"
                    />
                </div>
                <div class="LoginConInputBox">
                    <div style="display: flex;margin-left: 3vh;justify-content: space-between;width: 80%;align-items: center;height: 100%;">
                        <div style="white-space: nowrap;height: 70%;display: flex;align-items: center;position: relative;top: -0.2vh;color: rgb(134,171,249);">验证码</div>
                        <input style="width: 55%;height: 70%;color: rgb(134,171,249);" v-model="LoginForm.captcha" type="text"/>
                        <div style="height: 3.5vh;width: 8vh;position: relative;top: 0vh;left: 3.5vh;">
                            <img :src="imgData" @click="getVerify()"
                                style="width: 100%;height: 100%;cursor: pointer;"/>
                        </div>
                    </div>
                </div>
                <div class="LoginConAnNiu" @click="JyRegister">登陆</div>
                <div class="LoginConBottom">
                    <div style="display: flex; margin-bottom: 2vh">
                        注册成功？
                        <div
                            @click="$router.push('/login')"
                            style="text-decoration: underline; cursor: pointer"
                        >
                            去登陆
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 悬浮图标 -->
        <!-- <div class="LeftSuspensionBox">
          <div class="LeftSuspensionBoxTop"></div>
          <div class="LeftSuspensionBoxMiddle"></div>
          <div class="LeftSuspensionBoxButtom"></div>
      </div> -->
        <!-- 悬浮图标 -->
        <div class="LeftSuspensionBox">
            <div class="LeftSuspensionBoxTop">
                <div class="container">
                    <div class="wrap">
                        <div class="cube">
                            <div class="out-front">
                                <!-- <img src="../../assets/Login/LoginQiuOne.png" alt=""> -->
                            </div>
                            <div class="out-back">
                                <!-- <img src="../../assets/Login/LoginQiuTwo.png" alt=""> -->
                            </div>
                            <div class="out-left">
                                <!-- <img src="../../assets/Login/LoginQiuthere.png" alt=""> -->
                            </div>
                            <div class="out-right">
                                <!-- <img src="../../assets/Login/LoginQiuFour.png" alt=""> -->
                            </div>
                            <div class="out-top">
                                <!-- <img src="../../assets/Login/LoginQiuFive.png" alt=""> -->
                            </div>
                            <div class="out-bottom">
                                <!-- <img src="../../assets/Login/LoginQiuSix.png" alt=""> -->
                            </div>

                            <span class="in-front">
                                <img
                                    src="../../assets/Login/LoginQiuOneNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-back">
                                <img
                                    src="../../assets/Login/LoginQiuTwoNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-left">
                                <img
                                    src="../../assets/Login/LoginQiuThereNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-right">
                                <img
                                    src="../../assets/Login/LoginQiuFourNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-top">
                                <img
                                    src="../../assets/Login/LoginQiuFiveNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-bottom">
                                <img
                                    src="../../assets/Login/LoginQiuSixNei.png"
                                    alt=""
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="LeftSuspensionBoxMiddle"></div>
            <div class="LeftSuspensionBoxButtom"></div>
        </div>
        <!-- 校验功能 -->
        <!-- <div class="JiaoYanBox">
            <div class="JiaoYanCon">
                <div v-if="JyLoginZhangHao" style="margin-top: 12%">
                    请输入正确的账号
                </div>
                <div v-if="UserNoNull" style="margin-top: 12%">
                    账号不能为空
                </div>
            </div>
            <div class="JiaoYanCon">
                <div v-if="JyLoginPwd" style="margin-top: 12%">请输入密码</div>
                <div v-if="JyLoginPwdSix" style="margin-top: 12%">
                    6-16位数字、字母
                </div>
            </div>
            <div class="JiaoYanCon">
                <div v-if="JyRegisterTwoPwd" style="margin-top: 11%">
                    请确认密码相同
                </div>
                <div v-if="JyRegisterTwoPwdNoNull" style="margin-top: 11%">
                    二次密码不为空
                </div>
            </div>
            <div class="JiaoYanCon">
                <div v-if="captchaNoNull" style="margin-top: 11%">
                    验证码不为空
                </div>
            </div>
        </div> -->
    </div>
</template>
  
  <script>
import { log } from 'console'
export default {
    data() {
        return {
            yzcolor:'134,171,249',
            // 账号不为空
            UserNoNull: false,
            // 校验二次密码不为空
            JyRegisterTwoPwdNoNull: false,
            // 校验两次密码为一样
            JyRegisterTwoPwd: false,
            // 校验验证码不能为空
            JyLogincaptcha: false,
            // 校验登陆账号是否正确
            JyLoginZhangHao: false,
            // 校验登陆密码是否为空
            JyLoginPwd: false,
            // 校验登陆密码至少六位
            JyLoginPwdSix: false,
            Uuid: '',
            imgData: {},
            remember: false,
            flag: true,
            LoginForm: {
                // 账号
                mobile: '',
                // 密码
                password: '',
                // 二次验证密码
                checkpass: '',
                // 验证码
                captcha: '',
            },
        }
    },
    created() {
        this.getVerify()
    },
    mounted() {},
    methods: {
        // inputJyUser(){
        //     let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        //     if (reg.test(this.LoginForm.mobile)) {
        //         this.JyLoginZhangHao = false
        //     } else {
        //         this.JyLoginZhangHao = true
        //         this.UserNoNull = false
        //     }
        // },
        // 校验账号
        JyUserNull() {
            if (this.LoginForm.mobile == '') {
                document.getElementById("mobileinput").setAttribute("placeholder", "账号不能为空")
                this.yzcolor = '0,245,255'
            } else {
                document.getElementById("mobileinput").setAttribute("placeholder", "请输入账号")
                this.yzcolor = '134,171,249'
            }
        },
        // 校验密码
        JyPassWord() {
            if (this.LoginForm.password == '') {
                document.getElementById("pasinput").setAttribute("placeholder", "密码不能为空")
                this.yzcolor = '0,245,255'
            } else {
                document.getElementById("pasinput").setAttribute("placeholder", "请输入密码")
                this.yzcolor = '134,171,249'
            }
        },
        // 校验二次密码
        Jycheckpass() {
            if (this.LoginForm.checkpass == '') {
                // this.JyRegisterTwoPwdNoNull = true
            } else {
                // this.JyRegisterTwoPwdNoNull = false
                // if (this.LoginForm.checkpass != this.LoginForm.password) {
                //     this.JyRegisterTwoPwd = true
                // } else {
                //     this.JyRegisterTwoPwd = false
                // }
            }
        },
        // 注册逻辑
        // 注册校验
        JyRegister() {
            // 校验登陆账号 /[^\w\u4E00-\u9FA5`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/g
            //   let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            let reg = /[^\w]/g
            //   校验账号
            if (this.LoginForm.mobile == '') {
                this.UserNoNull = true
                this.JyLoginZhangHao = false
            } else {
                this.UserNoNull = false
                if (!reg.test(this.LoginForm.mobile)) {
                    this.JyLoginZhangHao = false
                } else {
                    this.JyLoginZhangHao = true
                    this.UserNoNull = false
                }
            }
            // 校验密码
            if (this.LoginForm.password == '') {
                this.JyLoginPwd = true
                this.JyLoginPwdSix = false
            } else {
                this.JyLoginPwd = false
                let reg = /^[0-9a-zA-Z]{6,16}$/
                if (reg.test(this.LoginForm.password)) {
                    this.JyLoginPwdSix = false
                } else {
                    this.JyLoginPwdSix = true
                }
            }
            // 校验二次密码
            if (this.LoginForm.checkpass == '') {
                this.JyRegisterTwoPwdNoNull = true
            } else {
                this.JyRegisterTwoPwdNoNull = false
                if (this.LoginForm.checkpass != this.LoginForm.password) {
                    this.JyRegisterTwoPwd = true
                } else {
                    this.JyRegisterTwoPwd = false
                }
            }
            if (this.LoginForm.captcha == '') {
                this.captchaNoNull = true
            } else {
                this.captchaNoNull = false
            }
            if (
                !this.UserNoNull &&
                !this.JyRegisterTwoPwdNoNull &&
                !this.JyRegisterTwoPwd &&
                !this.JyLogincaptcha &&
                !this.JyLoginZhangHao &&
                !this.JyLoginPwd &&
                !this.JyLoginPwdSix
            ) {
                this.postRegister()
            } else {
            }
        },
        // 注册请求
        postRegister() {
            var axios = require('axios')
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/auth/register',
                headers: {},
                data: {
                    mobile: this.LoginForm.mobile,
                    password: this.LoginForm.password,
                    captcha: this.LoginForm.captcha,
                    uuid: this.Uuid,
                },
            }
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        alert('注册成功')
                        this.$router.push('/login')
                        this.getVerify()
                    } else {
                        alert('账号已存在')
                        this.getVerify()
                    }
                })
                .catch(function (error) {})
        },
        // 注册逻辑结束
        // 获取图形验证码
        getVerify() {
            let that = this
            var axios = require('axios')
            var config = {
                method: 'get',
                url: this.$Schttp + '/captchaImage',
                headers: {
                    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDE2OCIsImlhdCI6MTY2MDc0NTgxMSwiZXhwIjoxNjYxMzUwNjExfQ.7xarDSPxdXlqyGSJ4bKRtBUK-TWNUAoO4TZoQtW0SxJyTWcXMm0UmiUEtXYcRVYyPWrJ-2-udR0-VfraMunQ8A',
                    //     Authorization: 'Bearer ' + this.$cookies.get("token"),
                    // 'Content-Type': 'application/json'
                },
            }
            axios(config)
                .then((response) => {
                    // 数据流转化为图片地址
                    that.Uuid = response.data.data.uuid
                    that.imgData =
                        'data:image/png;base64,' + response.data.data.img
                    console.log(that.imgData)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
    },
}
</script>
  
  <style lang="less" scoped>
  input::placeholder {
    color: rgb(var(--yzcolor));
}

:-ms-input-placeholder {
    color: rgb(var(--yzcolor));
}

::-webkit-input-placeholder {
    color: rgb(var(--yzcolor));
}
* {
    margin: 0;
    padding: 0;
}
input {
    background: none;
    outline: none;
    border: none;
}
.JiaoYanBox {
    width: 9%;
    height: 38%;
    position: relative;
    left: 82%;
    top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: rgb(0, 245, 255);
    .JiaoYanCon {
        width: 100%;
        height: 20%;
        display: flex;
    }
}
.FsEmainl {
    width: 7.5%;
    height: 4%;
    position: relative;
    left: 174vh;
    top: 60vh;
    color: rgb(0, 245, 255);
    font-size: 2vh;
    cursor: pointer;
}
.BigBox {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-image: url(../../assets/Login/LoginBG.png);
    background-size: 100% 100%;
    .TopNav {
        width: 100%;
        height: 10%;

        img {
            height: 85%;
            margin-left: 10vh;
            margin-top: 1vh;
            cursor: pointer;
        }
    }
    .Img {
        // position: fixed;
        right: 0px;
        bottom: 0px;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        source {
            min-width: 100%;
            min-height: 100%;
            height: auto;
            width: auto;
        }
    }
    // 登录/注册
    .Login {
        position: absolute;
        top: 18%;
        left: 50%;
        width: 45%;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .LoginConBox {
            width: 66%;
            height: 83%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-image: url(../../assets/Login/LoginBoxBG.png);
            background-size: 100% 100%;
            padding: 2vh 0;
            .LoginConTop {
                width: 100%;
                height: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3vh;
                color: white;
            }
            .LoginConInputBox {
                width: 70%;
                height: 7vh;
                margin: 1vh 0;
                background-color: rgb(16,62,121);
                display: flex;
                align-items: center;
                border: 1px solid rgb(3,113,203);
                border-radius: 5px;
                .UserNameico {
                    height: 50%;
                    width: 7%;
                    background-image: url(../../assets/Login/UserName.png);
                    background-size: 100% 100%;
                    margin-left: 3vh;
                }
                .PassWordico {
                    height: 50%;
                    width: 7%;
                    background-image: url(../../assets/Login/PassWord.png);
                    background-size: 100% 100%;
                    margin-left: 3vh;
                }
                input {
                    background: none;
                    outline: none;
                    -webkit-appearance: none;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    margin-left: 1vh;
                    color: rgb(var(--yzcolor));
                    font-size: 2vh;
                }
                input:focus {
                    border: none;
                }
            }
            .LoginConAnNiu {
                width: 70%;
                height: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 3vh;
                cursor: pointer;
                color: white;
                border-radius: 5px;
                background: linear-gradient(to right, rgb(1, 128, 235), rgb(2, 175, 251));
            }
            .LoginConBottom {
                width: 70%;
                height: 15%;
                // background-image: url(../../assets/Login/LoginConButtom.png);
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                font-size: 1.8vh;
                color: rgb(134,171,249);
            }
        }
    }
    .LeftSuspensionBox {
        position: absolute;
        top: 15%;
        left: 8%;
        width: 35%;
        height: 80vh;
        .LeftSuspensionBoxTop {
            width: 55%;
            height: 35%;
            position: relative;
            top: 0%;
            left: 22%;
            z-index: 1;
            animation: LeftSuspensionBoxTop 3s infinite; // 反反向再运行一遍
            animation-direction: alternate;
            /* 全模块定位和总布局 用定位top/left,没有margin但不脱标调位置 */

            .container {
                position: relative;
                top: -5vh;
                left: 15vh;
            }
            /* 主要用来装功能块在容器内保证定位 相当于该功能块的padding*/

            .container .wrap {
                width: 10vh;
                height: 10vh;
                top: 1vh;
                left: 1vh;
                margin: 0;
            }
            /* 功能块 */

            .container .wrap .cube {
                width: 10vh;
                height: 10vh;
                transform-style: preserve-3d;
                /* perspective: 500px; */
                transform: rotateX(-30deg) rotateY(-80deg);
                animation: ziZhuan linear 20s infinite;
                animation-direction: alternate-reverse;
                transition: all 0.5s;
            }

            @keyframes ziZhuan {
                0% {
                    transform: rotateX(0deg) rotateY(0deg);
                }
                100% {
                    transform: rotateX(360deg) rotateY(360deg);
                }
            }

            .container .wrap .cube div {
                position: absolute;
                top: 0;
                left: 0;
                width: 20vh;
                height: 20vh;
                background-color: rgb(0, 183, 255);
                z-index: -1;
                transition: all 0.5s;
                opacity: 0.45;
                outline: rgb(40, 226, 240) solid thick;
                box-shadow: rgba(17, 123, 194, 0.712) 0 0 15px 15px;
            }

            .container .wrap .cube div img {
                width: 100%;
                height: 100%;
            }

            .container .wrap .cube span {
                position: absolute;
                top: 5vh;
                left: 5vh;
                display: block;
                width: 10vh;
                height: 10vh;
                outline: rgba(21, 40, 211, 0.664) solid thin;
                background-color: rgba(11, 96, 224, 0.295);
            }

            .container .wrap .cube span img {
                width: 100%;
                height: 100%;
            }

            .container .wrap .cube .out-front {
                transform: rotateY(0deg) translateZ(10vh);
            }

            .container .wrap .cube .out-back {
                transform: translateZ(-10vh);
            }
            /* //y正方向逆时针 */
            .container .wrap .cube .out-left {
                transform: rotateY(-90deg) translateZ(10vh);
            }

            .container .wrap .cube .out-right {
                transform: rotateY(90deg) translateZ(10vh);
            }
            /*  x->正-上*/

            .container .wrap .cube .out-top {
                transform: rotateX(90deg) translateZ(10vh);
            }

            .container .wrap .cube .out-bottom {
                background-color: rgba(36, 238, 80, 0.253);
                transform: rotateX(-90deg) translateZ(10vh);
            }
            .container .wrap .cube .in-left {
                transform: rotateY(-90deg) translateZ(5vh);
            }
            .container .wrap .cube .in-right {
                transform: rotateY(90deg) translateZ(5vh);
            }
            .container .wrap .cube .in-back {
                transform: translateZ(-5vh);
            }
            .container .wrap .cube .in-front {
                transform: translateZ(5vh);
            }
            .container .wrap .cube .in-top {
                transform: rotateX(90deg) translateZ(5vh);
            }
            .container .wrap .cube .in-bottom {
                transform: rotateX(-90deg) translateZ(5vh);
            }
            .container .wrap .cube:hover .out-front {
                transform: rotateY(0deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-back {
                transform: rotateY(0deg) translateZ(-15vh);
            }
            .container .wrap .cube:hover .out-top {
                transform: rotateX(90deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-bottom {
                transform: rotateX(-90deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-right {
                transform: rotateY(90deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-left {
                transform: rotateY(-90deg) translateZ(15vh);
            }
        }
        @keyframes LeftSuspensionBoxTop {
            0% {
                top: 0%;
            }
            100% {
                top: 8%;
            }
        }
        .LeftSuspensionBoxMiddle {
            width: 100%;
            height: 90%;
            position: relative;
            top: -30%;
            background-image: url(../../assets/Login/LeftSuspensionBoxMiddle.png);
            background-size: 100% 100%;
            animation: LeftSuspensionBoxMiddle 3s infinite; // 反反向再运行一遍
            animation-direction: alternate;
        }
        @keyframes LeftSuspensionBoxMiddle {
            0% {
                top: -35%;
            }
            100% {
                top: -30%;
            }
        }
        .LeftSuspensionBoxButtom {
            width: 100%;
            height: 20vh;
            position: relative;
            top: -42%;
            left: 12%;
            background-image: url(../../assets/Login/LeftSuspensionBoxButtom.png);
            background-size: 100% 100%;
        }
    }
    //   .LeftSuspensionBox{
    //       position: absolute;
    //       top: 15%;
    //       left: 8%;
    //       width: 80vh;
    //       height: 80vh;
    //       .LeftSuspensionBoxTop{
    //           width: 55%;
    //           height: 35%;
    //           position:relative;
    //           top:0%;
    //           left:22%;
    //           z-index: 1;
    //           background-image: url(../../assets/Login/LeftSuspensionBoxTop.png);
    //           background-size: 100% 100%;
    //           animation:LeftSuspensionBoxTop 3s infinite;// 反反向再运行一遍
    //           animation-direction:alternate;
    //       }
    //       @keyframes LeftSuspensionBoxTop{
    //           0%{
    //               top:0%;
    //           }
    //           100%{
    //               top:8%;
    //           }
    //       }
    //       .LeftSuspensionBoxMiddle{
    //           width: 100%;
    //           height: 90%;
    //           position:relative;
    //           top:-30%;
    //           background-image: url(../../assets/Login/LeftSuspensionBoxMiddle.png);
    //           background-size: 100% 100%;
    //           animation:LeftSuspensionBoxMiddle 3s infinite;// 反反向再运行一遍
    //           animation-direction:alternate;
    //       }
    //       @keyframes LeftSuspensionBoxMiddle{
    //           0%{
    //               top: -35%;
    //           }
    //           100%{
    //               top: -30%;
    //           }
    //       }
    //       .LeftSuspensionBoxButtom{
    //           width: 100%;
    //           height: 20vh;
    //           position:relative;
    //           top:-42%;
    //           left:12%;
    //           background-image: url(../../assets/Login/LeftSuspensionBoxButtom.png);
    //           background-size: 100% 100%;
    //       }
    //   }
}
</style>